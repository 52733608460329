<template>
  <v-app id="inspire">
    <Navigation></Navigation>
    <router-view></router-view>
    <Footer></Footer>
  </v-app>
</template>

<script>
  import Navigation from "@/components/Navigation";
  import Footer from "@/components/Footer";

  import firebase from 'firebase'

  export default {
    name: 'app',
    components: {
      Footer,
      Navigation,
    },
    mounted() {
      var config = {
        apiKey: "AIzaSyB3b6BeR8_ZJyufdQxJ-YyzWJuZDyJKMcI",
        authDomain: "ip138-notifications.firebaseapp.com",
        databaseURL: "https://ip138-notifications.firebaseio.com",
        projectId: "ip138-notifications",
        storageBucket: "ip138-notifications.appspot.com",
        messagingSenderId: "794662622279",
        appId: "1:794662622279:web:4b2b5c1b9f68ba57618d62"
      };

      firebase.initializeApp(config);

      // Retrieve Firebase Messaging object.
      // eslint-disable-next-line no-undef
      const messaging = firebase.messaging();
      // Add the public key generated from the console here.
      messaging.usePublicVapidKey("BB5Osz-jGmrDcdqw5WMtapgakfEw-3iDL_eRj1VoIIwdAavNVSkZVqNOSunk8IrVZiPQK8cuntCt4z8MySQk_MM");

      // Get Instance ID token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      messaging.getToken().then((currentToken) => {
        if (currentToken) {
          // eslint-disable-next-line no-console
          console.log(currentToken);
        } else {
          // Show permission request.
          // eslint-disable-next-line no-console
          console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI.
          // eslint-disable-next-line no-undef
        }
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log('An error occurred while retrieving token. ', err);
      });

    }
  }
</script>
