import Vue from 'vue'
import Router from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from "@/plugins/vuetify";
import msal from 'vue-msal'


import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import UUID from 'vue-uuid';


import { config } from './config';

import Start from "@/components/Start";
import Station from "@/components/Station";
import Legal from "@/components/Legal";
import About from "@/components/About";
import Train from "@/components/Train";
import GABWInfo from "@/components/GABWInfo";
import DBStation from "@/components/DBStation";
import './registerServiceWorker'


Vue.config.productionTip = false;

Vue.use(msal, {
  auth: {
    clientId: '3a0d11ef-712c-4cc7-9daf-47e551848d11',
    redirectUri: "https://" + window.location.hostname + ":" + window.location.port,
  },
  framework: {
    globalMixin: true,
  }
});
Vue.use(Router);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(UUID);
Vue.use(Vuex);
Vue.prototype.appConfig = config;

const router = new Router({
  routes: [
    {
      path: '/station/:ds100',
      name: 'Station',
      component: Station,
    },
    {
      path: '/',
      name: 'Start',
      component: Start,
    },
    {
      path: '/gabw_info',
      name: 'Go-Ahead Information',
      component: GABWInfo,
    },
    {
      path: '/legal',
      name: 'Legal',
      component: Legal,
    },
    {
      path: '/about',
      name: 'About',
      component: About,
    },
    {
      path: '/train/:nr',
      name: 'Train',
      component: Train,
    },
    {
      path: '/dbstation/:eva',
      name: 'DBStation',
      component: DBStation,
    },
    {
      path: '*',
      redirect: '/',
    }
  ],
});

new Vue({
  router,
  vuetify,
  render: h => h(App),
  created() {
    /*if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }*/
  }
}).$mount('#app');
