let config;

if (process.env.NODE_ENV === "production") {
    config = {
        $api_url: "https://trainsip138.azure-api.net/goaheadapiproxy",
        $lc_api_url: "https://gabwbot.lc.ip138.de",
        legal: {
            imprint: `
                Fabian Schwarz<br>
                <a href="mailto:fabian+uts@ip138.de">fabian+uts@ip138.de</a><br>
            `,
            data_protection: `
                No personal data is being processed.
            `,
        }
    };
} else {
    config = {
        $api_url: "https://trainsip138.azure-api.net/goaheadapiproxy",
        $lc_api_url: "https://gabwbot.lc.ip138.de",
        legal: {
            imprint: `
                Fabian Schwarz<br>
                <a href="mailto:fabian+uts@ip138.de">fabian+uts@ip138.de</a><br>
            `,
            data_protection: `
                No personal data is being processed.
            `,
        }
    };
}

export { config }
