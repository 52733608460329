import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);
const opts = {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: colors.blueGrey.darken1, // #E53935
        secondary: colors.blueGrey.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
      dark: {
        primary: colors.blueGrey.darken1, // #E53935
        secondary: colors.blueGrey.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
    }
  },
  options: {
    customProperties: true
  },
  icons: {
    iconfont: "mdi"
  }
};

export default new Vuetify(opts);
