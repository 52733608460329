<template>
  <v-footer
          color="primary"
          app
  >
    <!--<span class="white--text" v-if="user !==null">
      Logged in as {{user.name}}
    </span>-->
  </v-footer>

</template>

<script>
  export default {
    name: 'Footer',
    data () {
      return {
        user: null,
      }
    },
    created() {
      this.user = this.msal.user
    }
  }
</script>

