<template>
  <v-main>
    <v-container>
      <v-alert
              type="info"
      >
        Currently this app only provides information about Go-Ahead Baden-Württemberg.
        The underlaying interfaces may provide incorrect or inconsistent information.
      </v-alert>
    </v-container>

    <v-container>
      <v-select
              v-model="selectedStation"
              :items="stationList"
              item-text="station"
              item-value="abbr"
              label="Station"
              autocomplete="off"
      ></v-select>
      <router-link :to="routeStation">
          <v-btn
                  elevation="2"
                  color="primary"
          >
            Show Station Board
          </v-btn>
        </router-link>
      </v-container>
      <v-container>
        <v-text-field
                label="Train Number"
                v-model="selectedTrain"
                type="number"
        ></v-text-field>
        <router-link :to="routeTrain">
          <v-btn
                  elevation="2"
                  color="primary"
          >
            Show Train
          </v-btn>
        </router-link>
      </v-container>

    <v-container>
      <router-link to="gabw_info">
        <v-btn
                elevation="2"
                color="primary"
        >
          Go-Ahead Traffic Info
        </v-btn>
      </router-link>
    </v-container>
    <v-container>
        <v-btn
                href="https://marudor.de"
                target="_blank"
                elevation="2"
        >
          <v-icon
                  left
          >
            mdi-open-in-new
          </v-icon>
          marudor.de for other operators
        </v-btn>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    data: function () {
      return {
        selectedStation: '',
        selectedTrain: '',
        stationList: [
          { abbr: 'TA', station: 'Aalen Hbf' },
          { abbr: 'TAD', station: 'Adelsheim Ost' },
          { abbr: 'TAM', station: 'Amstetten (Württ)' },
          { abbr: 'NAN', station: 'Ansbach' },
          { abbr: 'TB', station: 'Backnang' },
          { abbr: 'TBF', station: 'Bad Friedrichshall Hbf' },
          { abbr: 'TBS', station: 'Beimerstetten' },
          { abbr: 'TBE', station: 'Besigheim' },
          { abbr: 'TBM', station: 'Bietigheim-Bissingen' },
          { abbr: 'TBB', station: 'Böbingen (Rems)' },
          { abbr: 'TBOX', station: 'Boxberg-Wölchingen' },
          { abbr: 'HBS', station: 'Braunschweig Hbf' },
          { abbr: 'TC', station: 'Crailsheim' },
          { abbr: 'NDB', station: 'Dombühl' },
          { abbr: 'TEC', station: 'Ebersbach (Fils)' },
          { abbr: 'TEI', station: 'Eckartshausen-Ilshofen' },
          { abbr: 'TEF', station: 'Eislingen (Fils)' },
          { abbr: 'TEL', station: 'Ellwangen' },
          { abbr: 'TES', station: 'Essingen (b Aalen)' },
          { abbr: 'TE', station: 'Esslingen (Neckar)' },
          { abbr: 'TEU', station: 'Eubigheim' },
          { abbr: 'TFAU', station: 'Faurndau' },
          { abbr: 'TFI', station: 'Fichtenberg' },
          { abbr: 'TFB', station: 'Fornsbach' },
          { abbr: 'TGAW', station: 'Gaildorf West' },
          { abbr: 'TGRN', station: 'Gaubüttelbrunn' },
          { abbr: 'TG', station: 'Geislingen (Steige)' },
          { abbr: 'TGW', station: 'Geislingen West' },
          { abbr: 'TGLH', station: 'Gerlachsheim' },
          { abbr: 'TGN', station: 'Geroldshausen' },
          { abbr: 'TGI', station: 'Gingen (Fils)' },
          { abbr: 'TGL', station: 'Goldshöfe' },
          { abbr: 'TGO', station: 'Göppingen' },
          { abbr: 'TGRS', station: 'Grünsfeld' },
          { abbr: 'TH', station: 'Heilbronn Hbf' },
          { abbr: 'THST', station: 'Heilbronn Sülmertor' },
          { abbr: 'NHE', station: 'Heilsbronn' },
          { abbr: 'THZ', station: 'Herbolzheim (Jagst)' },
          { abbr: 'THOF', station: 'Hofen (b Aalen)' },
          { abbr: 'TJZ', station: 'Jagstzell' },
          { abbr: 'RKDU', station: 'Karlsruhe-Durlach' },
          { abbr: 'RK', station: 'Karlsruhe Hbf' },
          { abbr: 'TKM', station: 'Kirchheim (Neckar)' },
          { abbr: 'TKU', station: 'Kirchheim (Unterfr)' },
          { abbr: 'TKF', station: 'Königshofen (Baden)' },
          { abbr: 'TKUC', station: 'Kuchen' },
          { abbr: 'TL', station: 'Lauda' },
          { abbr: 'TLN', station: 'Lauffen (Neckar)' },
          { abbr: 'NLWI', station: 'Leutershausen-Wiedersbach' },
          { abbr: 'TLON', station: 'Lonsee' },
          { abbr: 'TLO', station: 'Lorch (Württ)' },
          { abbr: 'TLU', station: 'Ludwigsburg' },
          { abbr: 'TML', station: 'Möckmühl' },
          { abbr: 'TMOE', station: 'Mögglingen (b Gmünd)' },
          { abbr: 'TM', station: 'Mühlacker' },
          { abbr: 'TMT', station: 'Murrhardt' },
          { abbr: 'TN', station: 'Neckarsulm' },
          { abbr: 'TND', station: 'Neudenau' },
          { abbr: 'TNO', station: 'Nordheim (Württ)' },
          { abbr: 'NN', station: 'Nürnberg Hbf' },
          { abbr: 'NNSW', station: 'Nürnberg-Schweinau' },
          { abbr: 'NNST', station: 'Nürnberg-Stein' },
          { abbr: 'TOP', station: 'Oppenweiler (Württ)' },
          { abbr: 'TO', station: 'Osterburken' },
          { abbr: 'TPH', station: 'Pforzheim Hbf' },
          { abbr: 'TP', station: 'Plochingen' },
          { abbr: 'TPL', station: 'Plüderhausen' },
          { abbr: 'TRF', station: 'Reichenbach (Fils)' },
          { abbr: 'TRCB', station: 'Reichenberg' },
          { abbr: 'TRO', station: 'Roigheim' },
          { abbr: 'TRBG', station: 'Rosenberg (Baden)' },
          { abbr: 'NRL', station: 'Roßtal' },
          { abbr: 'NSA', station: 'Sachsen (b Ansbach)' },
          { abbr: 'TSAL', station: 'Salach' },
          { abbr: 'NSNE', station: 'Schnelldorf' },
          { abbr: 'TSF', station: 'Schorndorf' },
          { abbr: 'TSCH', station: 'Schrezheim' },
          { abbr: 'TSG', station: 'Schwäbisch Gmünd' },
          { abbr: 'TSHT', station: 'Schwäbisch Hall-Hessental' },
          { abbr: 'TSWA', station: 'Schwabsberg' },
          { abbr: 'TSE', station: 'Sennfeld' },
          { abbr: 'TSY', station: 'Siglingen' },
          { abbr: 'TSC', station: 'Stuttgart-Bad Cannstatt' },
          { abbr: 'TS', station: 'Stuttgart Hbf' },
          { abbr: 'TSBM', station: 'Sulzbach (Murr)' },
          { abbr: 'TSD', station: 'Süßen' },
          { abbr: 'TUH', station: 'Uhingen' },
          { abbr: 'TU', station: 'Ulm Hbf' },
          { abbr: 'TUG', station: 'Untergriesheim' },
          { abbr: 'TUA', station: 'Urbach (b Schorndorf)' },
          { abbr: 'TURS', station: 'Urspring' },
          { abbr: 'TV', station: 'Vaihingen (Enz)' },
          { abbr: 'TWN', station: 'Waiblingen' },
          { abbr: 'TWY', station: 'Waldhausen (b Schorndorf)' },
          { abbr: 'TWH', station: 'Walheim (Württ)' },
          { abbr: 'TWA', station: 'Wasseralfingen' },
          { abbr: 'TWSH', station: 'Westerstetten Bstg' },
          { abbr: 'NWK', station: 'Wicklesgreuth' },
          { abbr: 'TWL', station: 'Wilferdingen-Singen' },
          { abbr: 'TWI', station: 'Winnenden' },
          { abbr: 'TWIT', station: 'Wittighausen' },
          { abbr: 'NWH', station: 'Würzburg Hbf' },
          { abbr: 'NWS', station: 'Würzburg Süd' },
          { abbr: 'TZI', station: 'Zimmern (b Grünsfeld)' },
          { abbr: 'TZU', station: 'Züttlingen' },
        ],
      }
    },
    props: {
      source: String,
    },
    computed: {
      routeStation: function () {
        return '/station/' + this.selectedStation
      },
      routeTrain: function () {
        return '/train/' + this.selectedTrain
      },
    },
    name: 'Start'
  }
</script>

