<template>
  <v-main>
    <v-container>
      <h5 class="text-h5">About</h5>
    </v-container>
    <v-container>
      <p>
        Go-Ahead Baden-Württemberg seems incapable of providing usable passenger information online.
      </p>
      <v-list dense>
        <v-subheader>Some problems</v-subheader>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Malformed HTML and Strange Encoding</v-list-item-title>
            <v-list-item-subtitle>
              Missing tags, not parsable
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              "API" that queries encoded HTML in a JSON from a .html file
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Caching for short term information</v-list-item-title>
            <v-list-item-subtitle>
              Server-side caching for traffic information (up to 24h)
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Client-side caching for up to two weeks
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Bad Usability</v-list-item-title>
            <v-list-item-subtitle>
              No Train-based View
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Information sprayed across different views
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>No Proactive Information</v-list-item-title>
            <v-list-item-subtitle>
              No Push-Service
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Confusing Twitter account with train numbers out of context
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Bad Quality</v-list-item-title>
            <v-list-item-subtitle>
              Missing or wrong platforms
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Not running trains displayed as punctually
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Accessibility</v-list-item-title>
            <v-list-item-subtitle>
              Only Information in German
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Malformed HTML can hinder screen readers
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list><br>
      <p>
        When I travel with Go-Ahead Baden-Württemberg, it is often complicated to find up-to-date info.
        That's why I set myself the goal to find and reverse engineer as many official and unofficial sources as possible.
        They should be provided as a meaningful REST API. Further ideas would be e.g. a webapp with better UX or a Twitter bot that tweets incident reports.
      </p>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'Legal'
  }
</script>

