<template>
  <v-content>
    <section id="station" class="section">
      <h1 class="title">Station {{ this.$route.params['eva'] }}</h1>
      <div v-if="messages.loading" class="notification">
        Loading...
      </div>
      <div v-if="trains.length == 0 && messages.success" class="notification">
        The result is empty. Probably the request station has no movements at the moment.
      </div>
      <div v-if="messages.timeout" class="notification is-danger is-light">
        The request timed out. Please check your internet connection.
      </div>
      <div v-if="messages.bad_input" class="notification is-danger is-light">
        Bad input. Did you enter a valid station (DS100)?
      </div>
      <div v-if="messages.server_error" class="notification is-danger is-light">
        An internal server error occurred. Did you enter a valid station (DS100)?
      </div>
      <table  v-if="messages.success"  class="table is-narrow">
        <thead>
          <tr>
            <td>Train</td>
            <td>Route</td>
            <td>Plan</td>
            <td>Real</td>
            <td>Platform</td>
            <td>Remarks</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="train in this.trains" v-bind:key="train.id">
            <td>
              <span>{{train.tl["@c"]}} {{train.tl["@n"]}}</span>
            </td>
          </tr>
        </tbody>

      </table>
    </section>
  </v-content>
</template>

<script>
  export default {
    data: function () {
      return {
        trains: [],
        messages: {
          timeout: false,
          bad_input: false,
          server_error: false,
          loading: false,
          success: false,
        }
      }
    },
    computed: {
      eva: function() {
        return this.$route.params['eva'];
      }
    },
    watch: {
      'eva': {
        handler: 'update',
      }
    },
    mounted: function () {
      this.update()
    },
    methods: {
      getTrainLink: function (nr) {
        return '/train/' + nr;
      },
      update: function () {
        this.messages.loading = true;
        this.axios
          .get(this.appConfig.$api_url + '/DBfetchStation?eva=' + this.eva)
          .then(response => {
            this.trains = response.data.s;
            this.messages.success = true;
            this.messages.loading = false;
            this.messages.timeout = false;
            this.messages.bad_input = false;
            this.messages.server_error = false;
          })
          .catch(error => {
            this.messages.loading = false;
            this.messages.timeout = false;
            this.messages.bad_input = false;
            this.messages.server_error = false;
            if (typeof error.response !== 'undefined') {
              if (error.response.status == 400) {
                this.messages.bad_input = error;
                this.messages.success = false;
              } else if (error.response.status == 500) {
                this.messages.server_error = error;
                this.messages.success = false;
              } else {
                this.messages.timeout = error;
              }
            } else {
              this.messages.timeout = error;
            }
          })
      }
    },
    name: 'Station',
  }
</script>

<style scoped>
  .is-cnl {
    text-decoration: line-through;
  }
</style>