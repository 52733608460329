<template>
  <v-main>

    <v-container>
      <h5 class="text-h5">Train Number {{ this.$route.params['nr'] }}</h5>
      <v-alert
              v-if="messages.loading"
      >
        Loading...
      </v-alert>
      <v-alert
              v-if="stops.length == 0 && messages.success"
              type="info"
      >
        The result is empty. Probably the request train is not running at the moment.
      </v-alert>
      <v-alert
              v-if="messages.timeout"
              type="error"
      >
        The request timed out. Please check your internet connection.
      </v-alert>
      <v-alert
              v-if="messages.bad_input"
              type="error"
      >
        Bad input. Did you enter a valid train number (e. g. 19488)?
      </v-alert>
      <v-alert
              v-if="messages.server_error"
              type="error"
      >
        An internal server error occurred. Did you enter a valid train number (e. g. 19488)?
      </v-alert>
    </v-container>
    <v-container v-if="messages.success">

      <v-data-table
        dense
        :headers="headers"
        :items="stops"
        :items-per-page="50"
        class="elevation-1"
      >

       <!-- <thead>
        <tr>
          <td>Stop</td>
          <td>Plan</td>
          <td>Real</td>
          <td>Platform</td>
          <td>Remarks</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="stop in this.stops" v-bind:key="stop.station">
          <td>{{stop.station}}</td>

          <td>
            <span v-bind:class="{ 'is-cnl has-text-danger has-text-weight-bold': stop.arr.cnl} ">{{stop.arr.time.plan}}</span><br>
            <span v-bind:class="{ 'is-cnl has-text-danger has-text-weight-bold': stop.dep.cnl} ">{{stop.dep.time.plan}}</span><br>
          </td>

          <td>
            <span v-bind:class="[{ 'has-text-danger': stop.arr.time.delay > 5 }, { 'has-text-warning': stop.arr.time.delay > 2 }, 'has-text-success']"><span v-if="stop.arr.time.real">+{{stop.arr.time.delay}}</span> {{stop.arr.time.real}}</span><br>
            <span  v-bind:class="[{ 'has-text-danger': stop.dep.time.delay > 5 }, { 'has-text-warning': stop.arr.time.delay > 2 }, 'has-text-success']"><span v-if="stop.dep.time.real">+{{stop.dep.time.delay}}</span> {{stop.dep.time.real}}</span><br>
          </td>


          <td class="has-text-error has-text-weight-bold">
            <span>{{stop.arr.plat.real}}</span><br>
            <span>{{stop.dep.plat.real}}</span><br>
          </td>
          <td>
            <span>{{stop.arr.remark}}</span><br>
            <span>{{stop.dep.remark}}</span><br>
          </td>
        </tr>
        </tbody>-->

      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    data: function () {
      return {
        stops: [],
        messages: {
          timeout: false,
          bad_input: false,
          server_error: false,
          loading: false,
          success: false,
        },
        headers: [
          { text: 'Stop', value: 'station' },
          { text: 'Arr Plan', value: 'arr.time.plan' },
          { text: 'Arr Delay', value: 'arr.time.delay' },
          //{ text: 'Arr Curr', value: 'arr.time.real' },
          { text: 'Dep Plan', value: 'dep.time.plan' },
          { text: 'Dep Delay', value: 'dep.time.delay' },
          //{ text: 'Dep Curr', value: 'dep.time.real' },
          { text: 'Arr Plat', value: 'arr.plat.real' },
          { text: 'Dep Plat', value: 'dep.plat.real' },
          { text: 'Arr Info', value: 'arr.plat.remark' },
          { text: 'Dep Info', value: 'dep.plat.remark' },
        ],
      }
    },
    computed: {
      nr: function() {
        return this.$route.params['nr'];
      }
    },
    watch: {
      'nr': {
        handler: 'update',
      }
    },
    mounted: function () {
      this.update();
      setInterval(function () {
        this.update();
      }.bind(this), 60000);
    },
    methods: {
      update: function () {
        this.messages.loading = true;
        this.axios
                .get(this.appConfig.$api_url + '/fetchTrain?nr=' + this.nr)
                .then(response => {
                  function compare(a, b) {
                    if (a.sortkey < b.sortkey)
                      return -1;
                    if (a.sortkey > b.sortkey)
                      return 1;
                    return 0;
                  }
                  this.stops = response.data.stops.sort(compare);
                  this.messages.success = true;
                  this.messages.loading = false;
                  this.messages.timeout = false;
                  this.messages.bad_input = false;
                  this.messages.server_error = false;
                })
                .catch(error => {
                  this.messages.loading = false;
                  this.messages.timeout = false;
                  this.messages.bad_input = false;
                  this.messages.server_error = false;
                  if (typeof error.response !== 'undefined') {
                    if (error.response.status == 400) {
                      this.messages.bad_input = error;
                      this.messages.success = false;
                    } else if (error.response.status == 500) {
                      this.messages.server_error = error;
                      this.messages.success = false;
                    } else {
                      this.messages.timeout = error;
                    }
                  } else {
                    this.messages.timeout = error;
                  }
                })
      }
    },
    name: 'Train',
  }
</script>

<style scoped>
  .is-cnl {
    text-decoration: line-through;
  }
</style>
