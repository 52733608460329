<template>
  <v-main>

    <v-container>
      <h5 class="text-h5">Station {{ this.$route.params['ds100'] }}</h5>
      <v-alert
              v-if="messages.loading"
      >
        Loading...
      </v-alert>
      <v-alert
              v-if="trains.length == 0 && messages.success"
              type="info"
      >
        The result is empty. Probably the request station has no movements at the moment.
      </v-alert>
      <v-alert
              v-if="messages.timeout"
              type="error"
      >
        The request timed out. Please check your internet connection.
      </v-alert>
      <v-alert
              v-if="messages.bad_input"
              type="error"
      >
        Bad input. Did you enter a valid station (DS100)?
      </v-alert>
      <v-alert
              v-if="messages.server_error"
              type="error"
      >
        An internal server error occurred. Did you enter a valid station (DS100)?
      </v-alert>
    </v-container>
      <v-container v-if="messages.success">
      <v-data-table
              dense
              :headers="headers"
              :items="trains"
              :items-per-page="50"
              class="elevation-1"
      >

        <!--<thead>
          <tr>
            <td>Train</td>
            <td>Route</td>
            <td>Plan</td>
            <td>Real</td>
            <td>Platform</td>
            <td>Remarks</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="train in this.trains" v-bind:key="train.nr">
            <td>
              <router-link :to=getTrainLink(train.nr)>
                {{train.nr}}
              </router-link>
            </td>
            <td>
              <span v-bind:class="{ 'is-cnl has-text-danger has-text-weight-bold': train.arr.cnl} ">{{train.from}}</span><br>
              <span v-bind:class="{ 'is-cnl has-text-danger has-text-weight-bold': train.dep.cnl} " class="has-text-weight-bold">{{train.to}}</span><br>
            </td>
            <td>
              <span v-bind:class="{ 'is-cnl has-text-danger has-text-weight-bold': train.arr.cnl} ">{{train.arr.time.plan}}</span><br>
              <span v-bind:class="{ 'is-cnl has-text-danger has-text-weight-bold': train.dep.cnl} ">{{train.dep.time.plan}}</span><br>
            </td>

            <td>
              <span v-bind:class="[{ 'has-text-danger': train.arr.time.delay > 5 }, { 'has-text-warning': train.arr.time.delay > 2 }, 'has-text-success']"><span v-if="train.arr.time.real">+{{train.arr.time.delay}}</span> {{train.arr.time.real}}</span><br>
              <span  v-bind:class="[{ 'has-text-danger': train.dep.time.delay > 5 }, { 'has-text-warning': train.arr.time.delay > 2 }, 'has-text-success']"><span v-if="train.dep.time.real">+{{train.dep.time.delay}}</span> {{train.dep.time.real}}</span><br>
            </td>


            <td class="has-text-error has-text-weight-bold">
              <span>{{train.arr.plat.real}}</span><br>
              <span>{{train.dep.plat.real}}</span><br>
            </td>
            <td>
              <span>{{train.arr.remark}}</span><br>
              <span>{{train.dep.remark}}</span><br>
            </td>
          </tr>
        </tbody>-->

      </v-data-table>
    </v-container>

  </v-main>
</template>

<script>
  export default {
    data: function () {
      return {
        trains: [],
        messages: {
          timeout: false,
          bad_input: false,
          server_error: false,
          loading: false,
          success: false,
        },
        headers: [
          { text: 'Train Nr', value: 'nr' },
          { text: 'From', value: 'from' },
          { text: 'To', value: 'to' },
          { text: 'Arr Plan', value: 'arr.time.plan' },
          { text: 'Arr Cnl', value: 'arr.cnl' },
          { text: 'Arr Delay', value: 'arr.time.delay' },
          //{ text: 'Arr Curr', value: 'arr.time.real' },
          { text: 'Dep Plan', value: 'dep.time.plan' },
          { text: 'Dep Cnl', value: 'dep.cnl' },
          { text: 'Dep Delay', value: 'dep.time.delay' },
          //{ text: 'Dep Curr', value: 'dep.time.real' },
          { text: 'Arr Plat', value: 'arr.plat.real' },
          { text: 'Dep Plat', value: 'dep.plat.real' },
          { text: 'Arr Info', value: 'arr.plat.remark' },
          { text: 'Dep Info', value: 'dep.plat.remark' },
        ],
      }
    },
    computed: {
      ds100: function() {
        return this.$route.params['ds100'];
      }
    },
    watch: {
      'ds100': {
        handler: 'update',
      }
    },
    mounted: function () {
      this.update()
    },
    methods: {
      getTrainLink: function (nr) {
        return '/train/' + nr;
      },
      update: function () {
        this.messages.loading = true;
        this.axios
          .get(this.appConfig.$api_url + '/fetchStation?ds100=' + this.ds100)
          .then(response => {
            function compare(a, b) {
              if (a.sortkey < b.sortkey)
                return -1;
              if (a.sortkey > b.sortkey)
                return 1;
              return 0;
            }
            this.trains = response.data.trains.sort(compare);
            this.messages.success = true;
            this.messages.loading = false;
            this.messages.timeout = false;
            this.messages.bad_input = false;
            this.messages.server_error = false;
          })
          .catch(error => {
            this.messages.loading = false;
            this.messages.timeout = false;
            this.messages.bad_input = false;
            this.messages.server_error = false;
            if (typeof error.response !== 'undefined') {
              if (error.response.status == 400) {
                this.messages.bad_input = error;
                this.messages.success = false;
              } else if (error.response.status == 500) {
                this.messages.server_error = error;
                this.messages.success = false;
              } else {
                this.messages.timeout = error;
              }
            } else {
              this.messages.timeout = error;
            }
          })
      }
    },
    name: 'Station',
  }
</script>

<style scoped>
  .is-cnl {
    text-decoration: line-through;
  }
</style>
